<template>
<div>
    <div class="flex border-b border-blue-500">
        <div class="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 w-full pb-6">
            <div class="text-left">
                <label class="text-lg font-medium text-gray-600">Fecha:</label>
            </div>
            <div class="text-lg font-medium text-gray-800 text-left">
            {{ cotizacion.fechaCreacion }}
            </div>
            <div class="text-left relative">
                <label class="text-lg font-medium text-gray-600">Hora:</label>
            </div>
            <div class="text-lg font-medium text-gray-800 text-left relative">
            {{ cotizacion.horaCreacion }}
            </div>
            <div class="text-left relative">
                <label class="text-lg font-medium text-gray-600">Nombre cliente:</label>
            </div>
            <div class="text-lg font-medium text-gray-800 text-left relative">
            {{ cotizacion.nombre }}
            </div>
            <div class="text-left relative">
                <label class="text-lg font-medium text-gray-600">Apellidos:</label>
            </div>
            <div class="text-lg font-medium text-gray-800 text-left relative">
            {{ `${cotizacion.apellidoPaterno} ${cotizacion.apellidoMaterno}` }}
            </div>
            <div class="text-left relative">
                <label class="text-lg font-medium text-gray-600">Observación:</label>
            </div>
            <div class="text-lg font-medium text-gray-800 text-left relative">
                {{ cotizacion.observacion }}
            </div>
        </div>    
    </div>
    <h2 class="text-xl text-blue-700">Lentes cotizados</h2>

    <div class="shadow rounded-lg overflow-x-auto">
        <table class="w-full text-base divide-y divide-gray-200 whitespace-no-wrap overflow-hidden">
            <thead class="bg-blue-500 text-white">
                <tr>
                    <th class="py-1 px-2">Lente</th>
                    <th class="py-1 px-2">Distancia</th>
                    <th class="py-1 px-2">E.D</th>
                    <th class="py-1 px-2">C.D</th>
                    <th class="py-1 px-2">Grd.D</th>
                    <th class="py-1 px-2">E.I</th>
                    <th class="py-1 px-2">C.I</th>
                    <th class="py-1 px-2">Grd.I</th>
                    <th class="py-1 px-2">Armazón</th>
                    <th class="py-1 px-2">Filtro</th>
                    <th class="py-1 px-2">Cristal</th>
                    <th class="py-1 px-2">T.multi</th>
                    <th class="py-1 px-2">DP</th>
                    <th class="py-1 px-2">Valor</th>
                </tr>
            </thead>
            <tbody class="">
                <tr v-for="(lente,indice) in cotizacion.detalleCotizacion" :key="indice">
                    <td class="px-2 py-1 text-left border border-gray-200">{{ lente.tipoLente }}</td>
                    <td class="px-2 py-1 text-left border border-gray-200">{{ lente.tipoDistancia }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.esfericoOd }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.cilindricoOd }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.gradosOd }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.esfericoOi }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.cilindricoOi }}</td>
                    <td class="px-2 py-1 text-right border border-gray-200">{{ lente.gradosOi }}</td>
                    <td :title="lente.armazon" class="px-2 py-1 text-left border border-gray-200">
                    {{ `${lente.armazon.slice(0,6)}${lente.armazon.length > 6 ? '...' : ''}` }}
                    </td>
                    <td :title="lente.tipoFiltro" class="px-2 py-1 text-left border border-gray-200">
                        {{ `${lente.tipoFiltro.slice(0,6)}${lente.tipoFiltro.length > 6 ? '...' : ''}` }}
                    </td>
                    <td class="px-2 py-1 text-left border border-gray-200">{{ lente.tipoCristal == 'Policarbonato' ? 'Poli.' : lente.tipoCristal }}</td>
                    <td :title="lente.tipoMultifocal ? lente.tipoMultifocal : ''" class="px-2 py-1 text-left border border-gray-200">
                        {{ `${lente.tipoMultifocal ? lente.tipoMultifocal.slice(0,6) : ''}${lente.tipoMultifocal && lente.tipoMultifocal.length > 6 ? '...' : ''}` }}
                    </td>
                    <td class="border border-gray-200 py-1 px-2">{{ lente.dp }}</td>
                    <td class="text-right pr-2 py-1 border border-gray-200 px-2">{{ this.formatearCifra(lente.valorLente) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="inline-flex pt-5">
        <div class="px-2">
            <label class="text-xl sm:text-3xl font-medium text-gray-700 whitespace-no-wrap">Valor total:</label>
        </div>
        <div class="px-2">
            <label class="text-xl sm:text-3xl font-medium text-blue-600 whitespace-no-wrap">{{ `$ ${this.formatearCifra(cotizacion.valorTotal)}` }}</label>
        </div>
    </div>
</div>
</template>

<script>
import { separarPorMiles } from '@/utils/helpers'

export default {
    name: 'DetalleCotizacion',
    props: {
        cotizacion: {
            type: Object,
            default: null
        }
    },
    methods: {
        formatearCifra(valor) {
            return separarPorMiles(valor);
        }
    }
}
</script>