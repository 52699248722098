<template>
    <div class="w-64">
        <div>
            <h2 class="font-bold text-2xl text-center whitespace-no-wrap">ÓPTICA VANGUARDIA</h2>
        </div>
        <div class="border-b border-gray-800">
            <div class="flex flex-col text-sm">
                <div class="text-left font-semibold">
                    Direcciones
                </div>
                <div class="text-left font-medium">
                    - Ignacio Serrano 1111, Tomé
                </div>
                <div class="text-left font-medium">
                    - Manuel Montt 1067, Tomé
                </div>
                <div class="text-left font-medium">
                    - Pedro León Gallo 465, Coelemu
                </div>
            </div>
            <div class="grid grid-cols-3 text-sm my-2">
                <div class="text-left col-span-3 font-semibold">
                    Teléfonos
                </div>
                <div class="text-left">
                    I. Serrano:
                </div>
                <div class="text-right col-span-2">
                    412656907
                </div>
                <div class="text-left">
                    M. Montt:
                </div>
                <div class="text-right col-span-2">
                    412656374
                </div>
                <div class="text-left">
                    Coelemu:
                </div>
                <div class="text-right col-span-2">
                    422510306
                </div>
            </div>
            <div class="grid grid-cols-4 text-sm my-2">
                <div class="text-left">
                    Email:
                </div>
                <div class="text-right col-span-3">
                    opticavanguardia@gmail.com
                </div>
            </div>
        </div>
        <div id="">
            <slot name="contenido">
                Hola amigo! "Hola amigo?" Qué tonto!
            </slot>
        </div>
        <div class="font-semibold text-center text-xl">
            ¡Gracias por preferir Óptica Vanguardia!
        </div>
    </div>
    
</template>

<script>
export default {
    props: {
        
    }
}
</script>